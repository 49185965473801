import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Industries from "../../layouts/Industries"
import {graphql, useStaticQuery} from 'gatsby';

const IndustriesPageEn = () => {
  const data = useStaticQuery(graphql`
    query industriesHeaderImageEn {
      image: file(relativePath: { eq: "industries/header.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      allContentfulSecteurs(filter: {node_locale: {eq: "en-CA"}}, sort: {fields: createdAt}) {
        edges {
          node {
            name
            permalink
            contentful_id
            image {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)

  return (
    <Layout 
    lang="en-CA" 
    switchLink='/secteurs' 
    slider={true} 
    headerImage={data.image.childImageSharp} 
    title="An essential communication tool adapted to your industry." 
    subTitle="Whether with your employees or customers, we have the solution to your communication challenges." 
    anchorLink="#industries"
    anchorLinkText="Learn more">
      <Seo title="Communication Tool Customized to your Industry | Digital Signage Canada"
           description="No matter your industry and your audience, you will save time by using our infographics and animated templates that you can customize to your needs. Attratif, a digital signage Canada business."
           lang="en-CA"/>
      <Industries lang="en-CA" industries={data.allContentfulSecteurs.edges}/>
    </Layout>
  )
}

export default IndustriesPageEn