import React, {useState} from 'react';
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {colors} from '../../../style/constants.js';
import {Pharmacies, Finance, Factories, Communication, Restauration, Glasses, Tourism, Retail, More} from '../../../components/svgs/industries';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    textAlign: 'center',
    '& > svg': {
      position: 'absolute',
      right: 0,
    }
  },
  industry: {
    position: 'relative',
    boxSizing: 'border-box',
    borderRadius: 10,
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    '& h4': {
      background: colors.WHITE,
      color: colors.ATT_BLUE,
      width: '100%',
      height: 60,
      padding: '0 15px',
      boxSizing: 'border-box',
      position: 'absolute',
      bottom: 0,
      left: 0,
      zIndex: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  gradient: {
    opacity: 1,
    background: 'linear-gradient(to right, rgba(91, 192, 222, 0.5) 0%,rgba(88, 221, 170, 0.5) 100%)',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
    '& svg': {
      filter: 'brightness(0) invert(1)',
      width: 90,
      height: 90,
      marginBottom: 60,
      [theme.breakpoints.down('md')]: {
        width: 60,
        height: 60,
        [theme.breakpoints.down('xs')]: {
          width: 45,
          height: 45,
        },
      },
    }
  }
}));

const IndustriesTypeSection = (props) => {

  const [activeIndustry, setActiveIndustry] = useState('');
  const classes = useStyles();
  
  const renderIndustries = () => {
    return props.industries.map((industry, index) => {
      const renderIcon = () => {
        if (industry.node.contentful_id === "PWF1ScawoaaxnKIgjOtuc") {
          return <Pharmacies />
        }
        if (industry.node.contentful_id === "6ZaDyyZZ4mBcj8sAQtdw4s") {
          return <Finance />
        }
        if (industry.node.contentful_id === "626he1WN2uKETazng2SuCd") {
          return <Factories />
        }
        if (industry.node.contentful_id === "1uJmaotvSb75OCUDrKeY6o") {
          return <Communication />
        }
        if (industry.node.contentful_id === "72qEQKcsgYTajRzrrv7WcA") {
          return <Restauration />
        }
        if (industry.node.contentful_id === "2ucuM8w25zf95SjOxURGBL") {
          return <Glasses />
        }
        if (industry.node.contentful_id === "7olBztWibd2z1GSgYNQwOl") {
          return <Retail />
        }
        if (industry.node.contentful_id === "270Cghzi0aPNX6ab5ywlbo") {
          return <Tourism />
        }
        if (industry.node.contentful_id === "70OA8GcVMObd2NwScKZ0IC") {
          return <More />
        }
      }

      return (
        <Grid container item xs={6} sm={4} key={index}>
          { industry.node.contentful_id === "2ucuM8w25zf95SjOxURGBL" ? 
          <a href="https://www.micotv.ca/" target="_blank" rel="noopener noreferrer" key={index} className={classes.industry + ' clickable industry-' + (index + 1) + ' ' + activeIndustry} onMouseEnter={ () => setActiveIndustry(index + 1) } onMouseLeave={ () => setActiveIndustry('') }>
            <Grid className={classes.gradient} container justifyContent='center' alignItems='center'>
            {activeIndustry === index + 1 ? renderIcon() : ""}
            </Grid>
            <GatsbyImage image={industry.node.image.gatsbyImageData} alt={industry.node.name}/>
            <h4>{industry.node.name}</h4>
          </a>  
          :
          <Link to={industry.node.permalink} key={index} className={classes.industry + ' clickable industry-' + (index + 1) + ' ' + activeIndustry} onMouseEnter={ () => setActiveIndustry(index + 1) } onMouseLeave={ () => setActiveIndustry('') }>
            <Grid className={classes.gradient} container justifyContent='center' alignItems='center'>
            {activeIndustry === index + 1 ? renderIcon() : ""}
            </Grid>
            <GatsbyImage image={industry.node.image.gatsbyImageData} alt={industry.node.name}/>
            <h4>{industry.node.name}</h4>
          </Link>  
          }
        </Grid>
      );
    });
  };

  return (
    <section className={classes.wrapper} id="industries">
      <Container>
        <Grid container justifyContent='center' spacing={3}>
          {renderIndustries()}
        </Grid>
      </Container>
    </section>
  );
}

export default IndustriesTypeSection;