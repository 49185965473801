import React from 'react';
import IndustriesTypeSection from './IndustriesTypeSection';
import HomeContentSection from '../Home/HomeContentSection/';

const Industries = (props) => {
  return (
    <>
      <IndustriesTypeSection lang={props.lang} industries={props.industries}/>
      <HomeContentSection lang={props.lang}/>
    </>
  );
}
  
export default Industries;